<template>
    <div>
        <myBreadcrumb :nav="nav"></myBreadcrumb>
        <div class="item" v-for="(item, index) in tbOrgList" :key="index">
            <div class="title">
                <img src="@/assets/images/Frame-55.png" alt="" />
                <span>{{ item.name }}</span>
            </div>
            <div class="detail">{{ item.briefIntroduction }}</div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            nav: [
                {
                    title: '首页',
                    to: { path: '/' },
                },
                {
                    title: '关于本馆',
                    to: { path: '/about' },
                },
                {
                    title: '组织机构',
                },
            ],
            tbOrgList: [],
        };
    },
    created() {
        this.$hao.getLibrarysDetail(localStorage.getItem('id')).then((res) => {
            this.tbOrgList = res.data.tbOrgList;
        });
    },
};
</script>

<style scoped lang="less">
.item {
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;

    .title {
        display: flex;
        align-items: center;
        font-weight: 700;
        font-size: 24px;
        color: #333333;
        img {
            width: 32px;
            height: 32px;
            margin-right: 10px;
        }
    }

    .detail {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.5);
        align-self: stretch;
        margin-top: 12px;
    }
}
</style>
